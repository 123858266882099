import React from 'react';
import {Helmet} from "react-helmet";
import Top from './Top';
import ContentArea from './ContentArea';
import ServiceOffering from './ServiceOffering';
import FAQ from './FAQ';

function InvestorRelations() {
  const faqDesign = {
    width: '85%',
    paddingBottom: '45px',
  }

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What does an investor relations do?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Investor Relations (IR) manages communication between a company’s leadership and its investors. Responsibilities include updating financial performance, handling investor inquiries, developing corporate messaging, ensuring regulatory compliance, and monitoring market trends."
      }
    },{
      "@type": "Question",
      "name": "What falls under investor relations?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Investor Relations activities include financial reporting, investor communications, public relations, shareholder services, corporate governance, and crisis management."
      }
    },{
      "@type": "Question",
      "name": "What is the strategic role of investor relations?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The strategic role of IR involves enhancing company valuation, building investor confidence, facilitating capital access, providing market intelligence, and managing the company’s reputation."
      }
    },{
      "@type": "Question",
      "name": "What is the investor relations process?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The investor relations process includes: Preparation and Planning: Develop IR strategy and key messages. Information Dissemination: Release financial reports and conduct presentations. Engagement and Communication: Maintain dialogue with investors and respond to inquiries. Feedback and Analysis: Monitor investor feedback and market reactions. Compliance and Reporting: Ensure regulatory compliance and maintain accurate records."
      }
    },{
      "@type": "Question",
      "name": "What is a good investor relations?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Good investor relations are characterized by transparency, consistency, accessibility, proactivity, strategic alignment, reputation management, and adherence to ethical standards."
      }
    },{
      "@type": "Question",
      "name": "How can I contact the Investor Relations team at Amicus?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can contact our Investor Relations team by emailing business@amicusllp.com or by filling the form."
      }
    }]
  
  };
 

  return ( 
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Investor Relations Consultancy | Best Investor Relations Consultants | Investor Relations Advisors - Amicus Growth Advisors</title>
            <meta name="keyword" content="Investor relations, Investor Relations Consultant, investor relations advisors, investor relations strategy, investor relations consulting, investor relations and financial communications, ir consultancies, ir-consultancy" />
            <meta name="description" content="Our Investor Relations consultants simplifies R&D communications, can improve investor understanding and boost stock performance and company's valuation" />
            <link rel="canonical" href="https://www.amicusllp.com/services/investor-relations" />
            <script type="application/ld+json">
                {JSON.stringify(ldJson)}
            </script>
        </Helmet>

        <Top 
        image = './About-banner.png' 
        text="Investor Relations"
        heading="Investor Relations Service"
        desc="Our Investor Relations Services offers a range of B2B services to collaborate with other businesses or organizations."
        YTembed="C7_5oyuMq-4"
        /> 
 
    <ContentArea 
            h1="Investor Relations Strategy"
            p1="Investor relations strategy refers to the plan put in place by a company to manage and communicate effectively with its investors. The main goal is to establish and maintain positive relationships with current and potential investors, enhance the company's reputation, and increase its visibility in the market."
            
            p12="The investor relations strategy may involve regular communication and updates about the company's financial performance, hosting investor events and conferences, conducting roadshows to attract new investors, and providing financial guidance."
            p13="It is also crucial to maintain transparency and consistency in communication to build trust and credibility with investors. A well-executed investor relations strategy can help a company attract and retain investors, boost its stock value, and achieve its long-term business objectives."
            img="../IRelation.jpg"
            altTag="Investor-Relation-service"
            h2="Why Amicus Growth Advisors"
            p2="Amicus Growth Advisors stands out with an extensive industry expertise and track record of success. With a team of seasoned professionals, we excel in developing comprehensive investor relations strategies tailored to the specific needs of each client."
            p22="The company's commitment to transparency, effective communication, and building strong relationships with investors sets it apart. Amicus's depth of experience in navigating complex financial markets and regulatory landscapes ensures that clients receive the highest level of guidance and support. By choosing us, businesses can benefit from a strategic approach to investor relations that enhances their reputation, attracts investors, and drives long-term value."
            h3="Benefits of Investor Relations"
            p3="Firstly, it helps establish and maintain strong relationships with investors and shareholders, fostering confidence and trust in the company. IR facilitates effective communication of financial information, corporate strategy, and performance updates, enhancing transparency and credibility."
            p32="It assists in attracting and retaining investors, increasing liquidity in the company's stock. Furthermore, IR professionals monitor market trends and investor sentiments, providing valuable insights to management for informed decision-making. Overall, effective IR can contribute to better valuations, improved market perception, and increased access to capital."
            h4="We Focus on maximizing valuation growth"
            p4="we understand the power of effective communication and its impact on valuation. With every word we choose, we drive valuation for our clients. Our team of experienced professionals excels in crafting compelling narratives that highlight the unique strengths and growth potential of businesses."
            p42="We work closely with our clients to develop targeted messaging that resonates with investors, analysts, and stakeholders. Through strategic storytelling, we enhance perception, build trust, and ultimately increase valuation. Whether it's crafting press releases, investor presentations, or financial reports, our words are carefully chosen to convey value and maximize the potential for growth."
            />
            
            <ServiceOffering 
            heading="Investor Relations Service Offering"
            s1="Investor Communication Strategy"
            s2="Financial Reporting Support"
            s3="Market Intelligence and Analysis"
            s4="Crisis Communication Planning"
            />

<div style={{display:'flex', justifyContent:'center'}}>
          <div style={faqDesign}>
          <h2 style={{padding:'35px 0px'}}>Frequently Asked Questions</h2>
        <FAQ 
        th1="What does an investor relations do?"
        FaqLink="https://www.amicusllp.com/services/sme-ipo-consultants"
        ds1="SME IPOs "
        ds12="provide a viable route for small and medium businesses to raise funding and enhance their market visibility. The Investor Relation (IR) consultants manages communication between a company’s leadership and its investors. Responsibilities include updating financial performance, handling investor inquiries, developing corporate messaging, ensuring regulatory compliance, and monitoring market trends."
        th2="What falls under investor relations?"
        ds2="Investor Relations activities include financial reporting, investor communications, public relations, shareholder services, corporate governance, and crisis management."
        th3="What is the strategic role of investor relations?"
        ds3="The strategic role of IR involves enhancing company valuation, building investor confidence, facilitating capital access, providing market intelligence, and managing the company’s reputation."
        th4="What is the investor relations process?"
        ds4="Preparation and Planning: Develop IR strategy and key messages, Information Dissemination: Release financial reports and conduct presentations, Engagement and Communication: Maintain dialogue with investors and respond to inquiries, Feedback and Analysis: Monitor investor feedback and market reactions, Compliance and Reporting: Ensure regulatory compliance and maintain accurate records"
        th5="What is a good investor relations?"
        ds5="Good investor relations are characterized by transparency, consistency, accessibility, proactivity, strategic alignment, reputation management, and adherence to ethical standards."
        th6="How can I contact the Investor Relations team at Amicus?"
        ds6="You can contact our Investor Relations team by emailing business@amicusllp.com or by filling the form."
      
        />
        </div>
        </div>

   </div>
  )
}

export default InvestorRelations