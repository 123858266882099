import React from 'react';
import './Home.css';
import BannerAnimation from './BannerAnimation'
import HomeAbout from './HomeAbout'
import HomeServices from './HomeService/HomeServices'
import IndustryHome from './IndustryHome'
import TeamHome from './TeamHome/TeamHome'
import CustomCarousel from '../Assets/CustomCarousel'
import AfterBanner from './AfterBanner'
import { Container } from 'react-bootstrap'
import ModalPopup from '../ModalPopup/ModalPopup'
import TeamSection from './TeamHome/TeamSection';
import {Helmet} from "react-helmet";
import YoutubeEmbed from './YoutubeEmbed';
import Banner from './Banner';
import LinkedInTag from 'react-linkedin-insight';
 

function Home() {
  const [modalShow, setModalShow] = React.useState(true);

  // const disabled = !user.allowsThirdPartyCookies();
  // LinkedInTag.init(6866817, 'dc', disabled);
  // LinkedInTag.track(17399713);
  
  return (
    <div>

       <Helmet>
            <meta charSet="utf-8" />
            <title>Business Consultant | Business Advisor - Amicus Growth Advisors</title>
            <meta name="keyword" content="business consultant, business consultant in india, business advisor, strategy consultant, go to market strategist, SME IPO Consultant" />
            <meta name="description" content="Amicus Growth Advisors, India's leading business consultancy, specializes in strategy, market entry, and expert advising to propel your business forward." />
            <link rel="canonical" href="https://www.amicusllp.com" />
            <meta property="og:image" content="/Amicus_sign.jpg" /> 
        </Helmet>
        

  
      <ModalPopup
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Banner />
      {/* <BannerAnimation /> */}
      {/* <YoutubeEmbed embedId="iCfPw0h_5fo" />  */}
      <AfterBanner/>

     
      

      <HomeAbout />
        <section className='report-section'>
          <Container>
            <div className='section-heading text-start'>
              <h2>OUR REPORTS</h2>
            </div>
            <CustomCarousel />
          </Container>
        </section>
      <HomeServices />
      

      <IndustryHome />
      
      <TeamSection 
        heading='OUR CORE TEAM'
        />
    
    </div>
  )
}

export default Home