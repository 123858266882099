import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

import './FAQ.scss'

export default function FAQ({pageName, th1, ds1, ds12, th2, ds2, th3, ds3, th4, ds4, th5, ds5, th6, ds6, th7, ds7, th8, ds81, ds82, FaqLink, FaqLink2}) {

  return (
    <div className={pageName} >
      <Accordion defaultActiveKey="0" >   
        <Accordion.Item eventKey="0" >
          <Accordion.Header ><h3 style={{fontSize:'1.3rem'}}>{th1}</h3></Accordion.Header>
          <Accordion.Body >
          <a href={FaqLink} target='blank'>{ds1}</a> {ds12}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header><h3 style={{fontSize:'1.3rem'}}>{th2}</h3></Accordion.Header>
          <Accordion.Body>
          {ds2}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header><h3 style={{fontSize:'1.3rem'}}>{th3}</h3></Accordion.Header>
          <Accordion.Body>
          {ds3}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header><h3 style={{fontSize:'1.3rem'}}>{th4}</h3></Accordion.Header>
          <Accordion.Body>
          {ds4}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header><h3 style={{fontSize:'1.3rem'}}>{th5}</h3></Accordion.Header>
          <Accordion.Body>
          {ds5}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header><h3 style={{fontSize:'1.3rem'}}>{th6}</h3></Accordion.Header>
          <Accordion.Body>
          {ds6}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header><h3 style={{fontSize:'1.3rem'}}>{th7}</h3></Accordion.Header>
          <Accordion.Body>
          {ds7}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header><h3 style={{fontSize:'1.3rem'}}>{th8}</h3></Accordion.Header>
          <Accordion.Body>
          <a href={FaqLink2} target='blank'>{ds81}</a> {ds82}
          </Accordion.Body>
        </Accordion.Item>
        
      </Accordion>
    </div>
  )
}

