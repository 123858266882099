import React from 'react';
import './ContentArea.scss'
import ContactForm from '../ContactPage/ContactForm';

function ContentArea(props) {
  return (
    <div className='ContentArea'>
        <div className='ContentArea-left'>
            <div className='ContentArea-box'>
                <h2>{props.h1}</h2>
                <p>{props.p1}</p>
                <p>{props.p12}</p> 
                <p>{props.p13}</p>
                <a href={props.Imglink} target='blank' ><img  src={props.img} alt={props.altTag} style={{width:'95%', padding:'20px 0px'}}/></a>
                <h2>{props.h2}</h2>   
                <p>{props.p2}</p> 
                <p>{props.p22}</p>   
                <h2>{props.h3}</h2>
                <p>{props.p3}</p>
                <p>{props.p32}</p>

                {/* <div class="main">
                    <div class="circle"></div>
                </div> */}

                <h2>{props.h4}</h2>
                <p>{props.p4}</p>
                <p>{props.p42}</p>
                <h2>{props.h5}</h2>
                <p>{props.p5}</p>
                <p>{props.p52}</p>
                
                 

            </div>
        </div>
        <div className='ContentArea-right'>
            <div className='ContentArea-sidebar'>
                

                {/* <ContactForm/> */}
            </div>
            {/* <div>
                <ContactForm/>
            </div> */}
        </div>
    </div>
  )
}

export default ContentArea